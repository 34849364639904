// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-pricing-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/old-pricing.js" /* webpackChunkName: "component---src-pages-old-pricing-js" */),
  "component---src-pages-pricing-contact-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/pricing-contact.js" /* webpackChunkName: "component---src-pages-pricing-contact-js" */),
  "component---src-pages-pricing-js": () => import("/codebuild/output/src2132550798/src/qvrbx-website/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

